export const flattenCompanyData = (companyData) => {
  let flattenedData = {};

  // Flatten main keys
  Object.keys(companyData).forEach((key) => {
    if (key === "documents") {
      // Do not flatten the 'documents' key
      flattenedData[key] = companyData[key];
    } else if (
      typeof companyData[key] === "object" &&
      companyData[key] !== null &&
      !Array.isArray(companyData[key])
    ) {
      // Flatten nested object keys, but not arrays
      Object.keys(companyData[key]).forEach((nestedKey) => {
        flattenedData[nestedKey] = companyData[key][nestedKey];
      });
    } else {
      flattenedData[key] = companyData[key];
    }
  });

  return flattenedData;
};
