/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { downloadAllFiles } from "../config/downloadMultipleFiles";
import { flattenCompanyData } from "../config/FlattenData";

const Detail = ({ data, children }) => {
  return (
    <>
      <div className="vh-100 d-flex align-items-center">
        {data.map((e, index) => {
          const flattenedData = flattenCompanyData(e);
          const keys = Object.keys(flattenedData);
          console.log(flattenedData, "flattenedData");
          return (
            <div className="container gx-0" key={index}>
              {children}
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      {keys
                        .filter((s) => s !== "documents")
                        .map((key, i) => (
                          <th key={i}>{key.replace(/_/g, " ")}</th>
                        ))}
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      {keys
                        .filter((s) => s !== "documents")
                        .map((key, i) => (
                          <td
                            className="text-white"
                            data-label={`Column ${i + 1}`}
                            key={i}
                          >
                            {flattenedData[key]}
                          </td>
                        ))}
                      <td className="text-white text-center">
                        <FiDownloadCloud
                          size={30}
                          onClick={() =>
                            downloadAllFiles(flattenedData.documents)
                          }
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Detail;
