export const downloadAllFiles = async (files) => {
  for (const file of files) {
    const response = await fetch(`https://carmen.devssh.xyz${file.name}`);
    console.log(response);
    const blob = await response.blob();
    const fileName = file.name.substring(
      file.name.lastIndexOf("/"),
      file.name.length
    );
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Use the original file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
};
