import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosInstance } from "../config/https";
import Header from "../layout/header";
import Detail from "./Detail";
import { initialOptions } from "../config/initialOptions";
const Home = () => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState(initialOptions);
  const [values, setValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleValues = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);

    // Logic to disable opposite options based on selected value
    const updatedOptions = initialOptions.map((option) => {
      let isDisabled = false;
      if (option.value.endsWith("-yes")) {
        // Check if 'yes' option is selected
        isDisabled = selectedOption.some(
          (sel) =>
            sel.value === option.value.replace("-yes", "-no") &&
            sel.value.endsWith("-no")
        );
      } else if (option.value.endsWith("-no")) {
        // Check if 'no' option is selected
        isDisabled = selectedOption.some(
          (sel) =>
            sel.value === option.value.replace("-no", "-yes") &&
            sel.value.endsWith("-yes")
        );
      }
      return { ...option, isDisabled };
    });

    // Update options with disabled state
    setOptions(updatedOptions);
  };

  // const logo = require("../assets/img/logo/logo.png");

  const getSearchedData = (event) => {
    setLoader(true);
    event.preventDefault();
    const formdata = new FormData();
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        formdata.append(key, values[key]);
      }
    }
    selectedOptions.forEach((option) => {
      const parts = option.value.split("-");
      const key = `asset[${parts[0]}]`;
      formdata.append(key, parts[1]);
    });

    axiosInstance
      .post(`company`, formdata)
      .then((response) => {
        const { status, message, companies } = response.data;

        if (status) {
          toast.success(`${companies.length} Record Found`);
          setLoader(false);
          setData(companies);
        } else {
          setLoader(false);
          toast.error(message);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  console.log(selectedOptions, "selectedOptions");
  return (
    <>
      <Header />
      <div className="bg-cover position-relative">
        <div className="overlay" />

        {data.length > 0 ? (
          <>
            <Detail data={data}>
              <div className="text-end mb-3 position-relative">
                <button
                  className="btn btn-primary text-white "
                  onClick={() => setData([])}
                >
                  Back
                </button>
              </div>
            </Detail>
          </>
        ) : (
          <>
            <div className="container p-5 position-relative">
              {/* <h1 className="text-center text-white">
              Femme Transporta Solutions
              </h1> */}

              <div className="row">
                <div className="col-md-1 col-sm-0" />
                <div className="col-md-10 col-sm-12">
                  <form
                    className="row"
                    role="search"
                    onSubmit={getSearchedData}
                  >
                    <div className="col-md-12 mb-3">
                      <h5 className="text-white mb-0">Assets</h5>
                      <Select
                        value={selectedOptions}
                        options={options}
                        isMulti
                        required
                        onChange={handleChange}
                        classNamePrefix="select"
                        className="w-100 h-25 basic-multi-select"
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <h5 className="text-white mb-0">State Served </h5>
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Enter State Served......"
                        name="state_served"
                        required
                        onChange={handleValues}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <h5 className="text-white mb-0">Driver Name(Optional)</h5>
                      <input
                        type="text"
                        name="driver"
                        onChange={handleValues}
                        className="form-control "
                        placeholder="Driver Name(Optional)"
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary text-white w-50 "
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? "Searching" : "Search"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
