import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/https";

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);
  const handleValues = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const logo = require("../../assets/img/logo/logo.png");
  const islogin = (event) => {
    setLoader(true);
    event.preventDefault();
    const formdata = new FormData();
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        formdata.append(key, values[key]);
      }
    }
    axiosInstance
      .post("login", formdata)
      .then((response) => {
        console.log(response);
        const { status, message, user, token } = response.data;

        if (status) {
          localStorage.setItem("token", token);
          localStorage.setItem("userDetails", JSON.stringify(user));
          toast.success(message);
          setLoader(false);
          navigate("/");
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  return (
    <div className="bg-cover">
      <div className="container">
        <div className="row align-items-center vh-100">
          <div
            className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-xs-12 card p-3"
            style={{ backgroundColor: "rgb(255 255 255 / 61%)" }}
          >
            <div className="text-center">
              <img src={logo} className="img-fluid w-100" alt="" />
            </div>
            <form onSubmit={islogin}>
              <div className="form-group mb-3">
                <label for="username">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="username"
                  required
                  onChange={handleValues}
                  name="email"
                  placeholder="Enter Username"
                />
              </div>
              <div className="form-group mb-3">
                <label for="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={handleValues}
                  required
                  placeholder="Enter Password"
                />
              </div>
              <div className="text-center">
                <button
                  disabled={loader}
                  type="submit"
                  className="btn btn-primary  mt-2 w-50"
                >
                  {loader ? "Loading" : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
