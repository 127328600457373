export const initialOptions = [
  {
    value: "private_chassis-yes",
    label: "Private Chassis - Yes",
    isDisabled: false,
  },
  {
    value: "private_chassis-no",
    label: "Private Chassis - No",
    isDisabled: false,
  },
  { value: "twic-yes", label: "Twic - Yes", isDisabled: false },
  { value: "twic-no", label: "Twic - No", isDisabled: false },
  { value: "eld-yes", label: "Eld - Yes", isDisabled: false },
  { value: "eld-no", label: "Eld - No", isDisabled: false },
  { value: "day_cabs-yes", label: "Day Cabs - Yes", isDisabled: false },
  { value: "day_cabs-no", label: "Day Cabs - No", isDisabled: false },
  {
    value: "parking_space-yes",
    label: "Parking Space- Yes",
    isDisabled: false,
  },
  { value: "parking_space-no", label: "Parking Space- No", isDisabled: false },
];
