import Home from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/style.css";
import Detail from "./pages/Detail";
import Login from "./pages/auth/login";
import { RequireAuth } from "./config/RequiredAuth";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/Detail/:id" element={<Detail />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
