import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const logo = require("../assets/img/logo/logo.png");
  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");
    navigate("/login");
  };
  return (
    <>
      <nav class={`navbar navbar-dark ${props.bg ? props.bg : "bg-dark"}`}>
        <div className="container-fluid">
          <Link className="navbar-brand" style={{ width: "25%" }} to="/">
            <img src={logo} className="img-fluid w-50" alt="" />
          </Link>
          <div className="navbar-brand d-flex align-items-center gap-2">
            <h6>
              Welcome {user?.first_name} {user?.last_name}...
            </h6>
            <button className="btn btn-primary" onClick={Logout}>
              {" "}
              Logout
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
